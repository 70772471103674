import { Box, Button, Heading, Flex, Text, Link as ChakraLink } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next';
import Card from 'shared/components/horizon-ui/card/Card';
import InfiniteTable from 'shared/components/table/InfiniteTable';
import useGetColors from 'shared/hooks/useGetColors';
import PageTitle from '../admin/PageTitle';
import { useGetColumns, useGetFilterFields, useGetGetBreadcrumbs } from './constants';
import { LinksT } from '../../api/types/links';
import { useGetLinks } from '../../api/requests/links';
import { LayoutsE, PathsE } from '../../routes';
import MatIcon from '../../../components/MatIcon';
import Link from '../../components/Link';

const Index = () => {
  const { t } = useTranslation();
  const { blue800 } = useGetColors();
  const columns = useGetColumns();
  const breadcrumbs = useGetGetBreadcrumbs();
  const filterFields = useGetFilterFields();

  return (
    <Box>
      <PageTitle title={t('dtos.link_other')} breadcrumbs={breadcrumbs} />
      <Card mb="20px" borderRadius="12px">
        <Flex alignItems="center" flexDirection={{ '3sm': 'row', sm: 'column' }}>
          <Link layout={LayoutsE.DASHBOARD} path={PathsE.CREATE_BOTS_LINK}>
            <Button
              fontSize="16px"
              color="white"
              backgroundColor="brand.500"
              fontWeight="600"
              _hover={{
                backgroundColor: "brand.400"
              }}
              paddingY="8px"
              paddingX="6px"
              display="flex"
              borderRadius="8px"
              alignItems="center"
              mb={{ sm: '6px', '3sm': '0' }}
            >
              <MatIcon name="add" size={24} color="gray800" variant="rounded" />
              <Text ml="8px">{t('links.createBotsLink')}</Text>
            </Button>
          </Link>
          <Link layout={LayoutsE.DASHBOARD} path={PathsE.CREATE_CHANNELS_LINK}>
            <Button
              fontSize="16px"
              ml={{ '3sm': "10px" }}
              color="white"
              backgroundColor="brand.500"
              fontWeight="600"
              _hover={{
                backgroundColor: "brand.400"
              }}
              paddingY="8px"
              paddingX="6px"
              display="flex"
              borderRadius="8px"
              alignItems="center"
            >
              <MatIcon name="add" size={24} color="gray800" variant="rounded" />
              <Text ml="8px">{t('links.createChannelsLink')}</Text>
            </Button>
          </Link>
        </Flex>
        <InfiniteTable<LinksT>
          query={useGetLinks}
          columns={columns}
          filterFields={filterFields}
          clickable
        />
      </Card>
    </Box>
  );
};

export default Index;
