import { Box, Heading } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next';
import Card from 'shared/components/horizon-ui/card/Card';
import InfiniteTable from 'shared/components/table/InfiniteTable';
import useGetColors from 'shared/hooks/useGetColors';
import PageTitle from '../admin/PageTitle';
import { useGetColumns, useGetFilterFields, useGetGetBreadcrumbs } from './constants';
import { SubscriptionsT } from '../../api/types/subscriptions';
import { useGetSubscriptions } from '../../api/requests/subscriptions';

const Index = () => {
  const { t } = useTranslation();
  const { blue800 } = useGetColors();
  const columns = useGetColumns();
  const breadcrumbs = useGetGetBreadcrumbs();
  const filterFields = useGetFilterFields();

  return (
    <Box>
      <PageTitle title={t('subscriptions.subscriptions')} breadcrumbs={breadcrumbs} />
      <Card mb="20px" borderRadius="12px">
        <InfiniteTable<SubscriptionsT>
          query={useGetSubscriptions}
          columns={columns}
          filterFields={filterFields}
          clickable
        />
      </Card>
    </Box>
  );
};

export default Index;
