import { Box, Button, Heading, Text, Flex } from '@chakra-ui/react'
import MatIcon from 'components/MatIcon';
import { useTranslation } from 'react-i18next';
import { useActivateOneBot, useDeactivateOneBot, useGetBots } from 'shared/api/requests/bots';
import { BotsT } from 'shared/api/types/bots';
import Card from 'shared/components/horizon-ui/card/Card';
import Link from 'shared/components/Link';
import InfiniteTable from 'shared/components/table/InfiniteTable';
import useGetColors from 'shared/hooks/useGetColors';
import { LayoutsE, PathsE } from 'shared/routes';
import PageTitle from '../admin/PageTitle';
import { useGetColumns, useGetFilterFields, useGetGetBreadcrumbs } from './constants';

const Index = () => {
  const { t } = useTranslation();
  const { blue800 } = useGetColors();
  const { mutate: activateMutation } = useActivateOneBot();
  const { mutate: deactivateMutation } = useDeactivateOneBot();
  const handleActivate = (botId: string) => activateMutation({ botId });
  const handleDeactivate = (botId: string) => deactivateMutation({ botId });

  const columns = useGetColumns({
    handleActivate,
    handleDeactivate,
  });
  const breadcrumbs = useGetGetBreadcrumbs();
  const filterFields = useGetFilterFields();

  return (
    <Box>
      <PageTitle title={t('bots.bots')} breadcrumbs={breadcrumbs} />
      <Card mb="20px" borderRadius="12px">
        <Flex alignItems="center">
          <Link layout={LayoutsE.DASHBOARD} path={PathsE.CREATE_BOT}>
            <Button
              fontSize="16px"
              ml="20px"
              color="white"
              backgroundColor="brand.500"
              fontWeight="600"
              _hover={{
                backgroundColor: "brand.400"
              }}
              paddingY="8px"
              display="flex"
              borderRadius="8px"
              alignItems="center"
            >
              <MatIcon name="add" size={24} color="gray800" variant="rounded" />
              <Text ml="8px">{t('bots.createBot')}</Text>
            </Button>
          </Link>
        </Flex>
        <InfiniteTable<BotsT>
          query={useGetBots}
          columns={columns}
          filterFields={filterFields}
          clickable
        />
      </Card>
    </Box>
  );
};

export default Index;
