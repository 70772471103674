export const en = {
  "dtos": {
    "link_one": "Link",
    "link_other": "Links",
  },
  "general": {
    "home": "Home",
    "administrator": "Administrator",
    "administrators": "Administrators",
    "approveTo": "Do you want to {{ phrase }}?",
    "createAdministrator": "Create Administrator",
    "viewAdministrator": "View Administrator",
    "editAdministrator": "Edit Administrator",
    "pageNotFound": "Page not found",
    "personalData": "Personal Data",
    "filters": "Filters",
    "table": {
      "dtoTable": "{{ dtoName }} table",
      "pagination": "Showing {{currCountOfRows}} to {{toCountOfRows}} of {{total}} entries",
      "noData": "No Data"
    }
  },
  "buttons": {
    "apply": "Apply",
    "search": "Search",
    "select": "Select",
    "cancel": "Cancel",
    "edit": "Edit",
    "create": "Create",
  },
  "profile": {
    "hey": "Hey, {{firstName}} {{middleName}} {{lastName}}",
    "logOut": "Log out"
  },
  "subscribers": {
    "subscribers": "Subscribers",
    "createSubscriber": "Create subscriber",
    "editSubscriber": "Edit subscriber",
    "viewSubscriber": "View subscriber",
    "subscribersTable": "Subscribers table",
    "table": {
      "id": "ID",
      "identifier": "Identifier",
      "name": "Name",
      "username": "Username",
      "bot": "Bot",
      "resources": "Resources",
      "buyer": "Buyer",
      "source": "Source",
      "messages": "Messages",
      "createdAt": "Created at",
      "updatedAt": "Updated at"
    }
  },
  "channels": {
    "channels": "Channels",
    "createChannel": "Create subscriber",
    "editChannel": "Edit subscriber",
    "viewChannel": "View subscriber",
    "channelsTable": "Channels table",
    "table": {
      "id": "ID",
      "identifier": "Identifier",
      "name": "Name",
      "createdAt": "Created at",
      "updatedAt": "Updated at"
    }
  },
  "subscriptions": {
    "subscriptions": "Subscriptions",
    "createSubscription": "Create subscription",
    "editSubscription": "Edit subscription",
    "viewSubscription": "View subscription",
    "subscriptionsTable": "Subscriptions table",
    "table": {
      "id": "ID",
      "identifier": "Identifier",
      "username": "Username",
      "url": "Url",
      "name": "Name",
      "createdAt": "Created at",
      "updatedAt": "Updated at",
      "buyer": "Buyer",
      "source": "Source",
      "resource": "Resource"
    }
  },
  "bots": {
    "bots": "Bots",
    "createBot": "Create bot",
    "editBot": "Edit bot",
    "viewBot": "View bot",
    "deleteBot": "Delete bot",
    "botsTable": "Bots table",
    "table": {
      "id": "ID",
      "identifier": "Identifier",
      "name": "Name",
      "createdAt": "Created at",
      "updatedAt": "Updated at",
      "username": "Username",
      "type": "Type",
    }
  },
  "links": {
    "links": "Links",
    "support": "Support",
    "contactDeveloper": "Contact Developer",
    "createBotsLink": "Create Bot's Link",
    "createChannelsLink": "Create Channel's Link"
  },
  "footer": {
    "allRightsReserved": "All rights reserved"
  },
  "fields": {
    "channel_one": "Channel",
    "channel_other": "Channels"
  },
  "forms": {
    "enterSource": "Enter Source",
    "enterBuyer": "Enter Buyer",
    "enterFbPixel": "Enter fbPixel",
    "enterFbToken": "Enter fbToken",
    "selectBotId": "Select BotId",
    "selectChannelId": "Select Channel Id",
    "source": "Source",
    "buyer": "Buyer",
    "fbPixel": "fbPixel",
    "fbToken": "fbToken",
    "channelId": "Channel ID",
    "botId": "Bot ID"
  },
};
